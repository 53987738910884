<template>
  <form-qr class="code-add" v-if="!isProcessing" />
</template>

<script>
import FormQr from '@/components/code/form/qr.vue'

export default {
  name: 'code_add',
  components: { FormQr },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object[]} 登録QRコードのオブジェクト一覧
     */
    collections () {
      return this.$store.getters['collections/collections']
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.code-add {
  position: relative;
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height} - #{$footer_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto $footer_height auto;
}
</style>
