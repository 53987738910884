<template>
  <div class="qr-form">
    <h2 class="qr-form__title">登録したいURL</h2>
    <input class="qr-form__input" v-model="postParams.url" type="text" placeholder="https://qration.me" />
    <h2 class="qr-form__title">表示名</h2>
    <input class="qr-form__input" v-model="postParams.name" type="text" placeholder="ホーム画面に表示されます" />
    <h2 class="qr-form__title">カテゴリー</h2>
    <div :class="postParams.categories.includes(category) ? 'qr-form__category--active' : 'qr-form__category'"
         v-for="category in displayCategories" :key="category" @click="setCategory(category)">{{ category }}</div>
    <v-btn class="qr-form__category--add" fab depressed @click="openAddCategoryPopup = true"><v-icon class="qr-form__category__icon">add</v-icon></v-btn>
    <v-btn class="qr-form__btn" :disabled="!canSubmit" depressed @click="submit()">{{ isEdit ? '編集する' : '登録する' }}</v-btn>
    <form-category v-if="openAddCategoryPopup"
                   :categories="displayCategories" @close="openAddCategoryPopup = false" @set-category="setCategory"></form-category>
  </div>
</template>

<script>
import string from '@/assets/lib/string'
import { deeplink } from '@/deeplink.js'
import FormCategory from '@/components/code/form/category'

export default {
  components: { FormCategory },
  mixins: [string],
  props: {
    collection: {
      type: Object
    }
  },
  data () {
    return {
      // 編集かどうか
      isEdit: false,
      // ポストパラメータ
      postParams: {
        url: '',
        name: '',
        categories: []
      },
      // DBに保存されていない一時的な表示用カテゴリー
      temporaryCategories: ['SNS'],
      // カテゴリー追加用のコンポーネントを開いているかどうか
      openAddCategoryPopup: false
    }
  },
  mounted () {
    // 編集の場合
    if (this.collection) {
      this.isEdit = true

      this.postParams.url = this.collection.url
      this.postParams.name = this.collection.collectionName
      this.postParams.categories = this.collection.categories
    }
  },
  computed: {
    /**
     * @return {Boolean} 必要最低限の記載があるかどうか
     */
    canSubmit () {
      return this.postParams.url.length > 0 && this.postParams.name.length > 0 && this.isCorrectURLFormat
    },
    /**
     * @return {Boolean} urlのフォーマットが正しいかどうかのチェック
     */
    isCorrectURLFormat () {
      return this.isValidURL(this.postParams.url)
    },
    /**
     * @return {String[]} 表示するカテゴリー名の一覧（名前順にソート）
     * 名前順にソートしたカテゴリー一覧
     * [ 'hoge1', 'hoge2', ... ]
     */
    displayCategories () {
      // 初期カテゴリとユーザーのカテゴリーを結合
      const categories = this.temporaryCategories.concat(this.$store.getters['collections/categories'])

      // 重複の削除
      const sortedCategories = Array.from(new Set(categories))
      return sortedCategories.sort()
    }
  },
  methods: {
    /**
     * QRコードの登録・編集
     */
    async submit () {
      this.$store.commit('setSubmitting', true)

      const actionName = this.isEdit ? 'collections/editCollection' : 'collections/createCollection'
      const result = await this.$store.dispatch(actionName, {
        cid: this.isEdit ? this.collection.cid : null,
        uid: this.$store.getters['auth/uid'],
        collectionName: this.postParams.name,
        url: this.postParams.url,
        categories: this.postParams.categories
      })

      /**
         * this.postParams.urlが下記パターンに該当する場合はディープリンクを作成する
         *
         * 1.個人の数字id
         *  https://www.facebook.com/profile.php?id=100012461591178
         *  https://www.facebook.com/100012461591178
         * 2.個人の文字列id
         *  https://www.facebook.com/takmurat
         * 3.組織の数字id
         *  https://www.facebook.com/profile.php?id=7040724713
         *  https://www.facebook.com/7040724713
         * 4.組織の文字列id
         *  https://www.facebook.com/yahoo
         * 5.グループの数字id
         *  https://www.facebook.com/profile.php?id=155076295120010
         *  https://www.facebook.com/groups/155076295120010
         *  https://www.facebook.com/155076295120010
         * 6.グループの文字列id
         *  https://www.facebook.com/groups/DeeplinkTest
         */
      const existsDeeplink = this.isEdit ? await this.$store.dispatch('deeplinks/getDeeplink', { cid: this.collection.cid }) != null : false
      const isRequiredDeeplink = deeplink.isRequiredDeeplink(this.postParams.url)
      if (isRequiredDeeplink) {
        const payload = {
          cid: this.isEdit ? this.collection.cid : result,
          url: this.postParams.url,
          deeplink: {
            schema: null,
            profileId: null,
            updatedAt: new Date()
          }
        }
        if (existsDeeplink) {
          this.$store.dispatch('deeplinks/updateDeeplink', payload)
        } else {
          payload.deeplink.createdAt = new Date()
          this.$store.dispatch('deeplinks/createDeeplink', payload)
        }
      }

      if (!isRequiredDeeplink && existsDeeplink) {
        // 編集前：ディープリンク対応URL -> 編集後：ディープリンク非対応URLのパターン
        // 既に登録されているディープリンクのレコードからprofileIdを削除
        const payload = {
          cid: this.collection.cid,
          deeplink: {
            schema: null,
            profileId: null,
            updatedAt: new Date()
          }
        }
        this.$store.dispatch('deeplinks/updateDeeplink', payload)
      }

      // 編集時、空カテゴリーを表示させないようカテゴリーをリセットする
      // ナビゲーションの活性カテゴリーが存在するかも確認する
      if (this.isEdit) {
        this.$store.commit('collections/resetCategory')
        this.$store.dispatch('resetActive')
      }

      this.$router.push({ name: 'list' })
    },
    /**
     * カテゴリーの追加 or 削除
     * @param {String} category 追加 or 削除したいカテゴリー名
     */
    setCategory (category) {
      // 先頭末尾の空白を除去
      const trimCategory = category.trim()

      // DB登録用カテゴリー変数に追加 or 削除
      const categorySet = new Set(this.postParams.categories)
      // 既に指定の値があるならカテゴリー削除、無ければカテゴリー追加の処理
      categorySet.has(trimCategory) ? categorySet.delete(trimCategory) : categorySet.add(trimCategory)
      this.postParams.categories = Array.from(categorySet)

      // 表示用カテゴリー変数に追加
      const isIncludedDisplayCategories = this.displayCategories.includes(trimCategory)
      // 表示カテゴリーに含まれていない場合
      if (!isIncludedDisplayCategories) {
        this.temporaryCategories.push(trimCategory)
        this.openAddCategoryPopup = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.qr-form {
  padding: 0 $padding_width;
  &__title {
    margin-top: 30px;
    font-size: 1.2rem;
    font-weight: bold;
    &:first-child {
      margin-top: 0;
    }
  }
  &__input {
    width: 100%;
    padding: 5px 0;
    font-size: 1.6rem;
    color: $black_color;
    border-bottom: 1px solid $black_color;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: $gray_color;
    }
  }
  &__category {
    display: inline-block;
    padding: 6px 15px;
    margin: 5px 5px 0 0;
    font-size: 1.2rem;
    color: $gray_color;
    vertical-align: middle;
    border: 2px solid $gray_color;
    border-radius: 15px;
    &--active {
      @extend .qr-form__category;
      color: $white_color;
      background-color: $orange_color;
      border: 2px solid $orange_color;
    }
    &--add {
      @extend .qr-form__category;
      padding: 0;
      color: $orange_color;
      border: 2px solid $orange_color;
      border-radius: 10px;
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: transparent;
      }
      &.v-btn--fab.v-size--default {
        width: 29px;
        min-width: 29px;
        height: 29px;
      }
    }
  }
  &__btn {
    display: block;
    margin: 60px auto 0 auto;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round).v-size--default {
      height: 40px;
      padding: 0 26px 0 26px;
      background-color: $orange_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $orange_lighten_color !important;
    }
  }
}
</style>
