/**
 * original founction library
 * [usage]
 * import string from '@/assets/lib/string'
 * mixins: [string]
 */
import Vue from 'vue'

export default Vue.extend({
  methods: {
    /**
     * @return {String} ユニークな文字列
     */
    getUniqueStr () {
      return new Date().getTime().toString(16) + Math.floor(1000 * Math.random()).toString(16)
    },
    /**
     * @param {Number} price 変換したい数値
     * @return {String} 日本円（カンマ付き）
     */
    getYen (price) {
      // 半角数字以外を削除
      let convertPrice = String(price).replace(/\D/g, '')
      // カンマを3桁ごとに挿入
      convertPrice = convertPrice.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      if (!convertPrice) {
        return null
      } else {
        return convertPrice
      }
    },
    /**
     * @param {String} url 対象のURL
     * @return {Boolean} URLフォーマットが正しいかどうか
     */
    isValidURL (url) {
      const validUrlFormat = /((http|https):\/\/(\S){1,}\.[a-zA-Z]{2,}(\/(\S){1,}|\/)?)/g
      return url.match(validUrlFormat)
    }
  }
})
