<template>
  <div class="form-category">
    <div class="form-category__area">
      <h2 class="form-category__area__title">新しいカテゴリーを追加する</h2>
      <input class="form-category__area__input" v-model="newCategory" type="text" placeholder="カテゴリーの名前" />
      <p :class="existCategory ? 'form-category__area__warning' : 'form-category__area__warning--none'">すでに登録されているカテゴリーです</p>
      <v-btn class="form-category__area__btn" :disabled="!canAddCategory" depressed @click="$emit('set-category', newCategory)">追加する</v-btn>
      <v-btn class="form-category__area__btn--back" text @click="$emit('close')">QRコード登録画面に戻る</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 表示されているカテゴリー名の一覧
    categories: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      // 新しいカテゴリー
      newCategory: ''
    }
  },
  computed: {
    /**
     * @return {Boolean} カテゴリーを追加できるかどうか
     */
    canAddCategory () {
      return this.newCategory.trim().length > 0 && !this.existCategory
    },
    /**
     * @return {Boolean} 表示しているカテゴリーとダブりがあるかどうか
     */
    existCategory () {
      const newCategory = this.newCategory.trim()
      return this.categories.includes(newCategory) || newCategory === 'ALL'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.form-category {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $popup_zindex;
  width: 100vw;
  height: 100vh;
  background-color: $white_color;
  &__area {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    max-width: 300px;
    text-align: center;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    &__title {
      font-size: 1.2rem;
      font-weight: bold;
    }
    &__input {
      width: 100%;
      padding: 5px 0;
      font-size: 1.6rem;
      color: $black_color;
      border-bottom: 1px solid $black_color;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: $gray_color;
      }
    }
    &__warning {
      margin-top: 5px;
      font-size: 1.2rem;
      color: $red_color;
      text-align: left;
      &--none {
        @extend .form-category__area__warning;
        visibility: hidden;
      }
    }
    &__btn {
      display: block;
      margin: 60px auto 0 auto;
      font-size: 1.2rem;
      color: $white_color;
      border-radius: 10px;
      &.v-btn:not(.v-btn--round).v-size--default {
        height: 40px;
        padding: 0 26px 0 26px;
        background-color: $orange_color;
      }
      &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        color: $white_color !important;
        background-color: $orange_lighten_color !important;
      }
      &--back {
        @extend .form-category__area__btn;
        margin-top: 10px;
        font-size: 1rem;
        color: $black_color;
        &.v-btn:not(.v-btn--round).v-size--default {
          height: auto;
          padding: 0;
          background-color: transparent;
        }
      }
    }
  }
}
</style>
